<template>
  <div class="wrap-block">
    <div class="content-blok service-block">
      <div class="servie-block">
        <div class="block-line"></div>
        <p class="service-info-title">案例展示范围</p>
        <p class="service-info-subtitle">多样的互联网形式供您选择，打造属于自己的互联网+</p>
        <el-row class="service-list">
          <el-col :span="6" v-for="(item, index) in bussList" :key="index">
            <div class="is-hover-shadow">
              <div class="service-title-shadow">
                <img :src="baseUrl + item.busCaseImage" :alt="item.busCaseDesc" style="border-radius: 50%" width="80px">
              </div>
            </div>
            <p class="service-name">{{ item.busCaseName }}</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="service-case">
      <div class="service-case">
        <div class="block-line"></div>
        <p class="case-title">{{ serviceIdeaInfo.ideaTitle }}</p>
        <p class="case-subtitle">{{ serviceIdeaInfo.ideaTitleDesc }}</p>
      </div>
    </div>
    <el-row class="studioBox">
      <el-col :span="10">
        <div class="studioLeftBox">
          <img class="studioLeftBoxImg" :src="isMobile ? serviceIdeaImg[2].path : serviceIdeaImg[1].path" :alt="serviceIdeaImg[1].name">
        </div>
      </el-col>
      <el-col :span="14">
        <div class="studioRightBox">
          <div class="block-line"></div>
          <h3 v-if="!isMobile">{{ serviceIdeaInfo.ideaSubTitle }}</h3>
          <p class="idea-desc">{{ serviceIdeaInfo.ideaDesc }}</p>
        </div>
      </el-col>
    </el-row>
    <div class="content-blok service-case">
      <div class="service-case">
        <div class="block-line"></div>
        <p class="case-title">服务案例</p>
        <p class="case-subtitle">已为客户定制多种多样的解决方案，助您轻松跨入“互联网+”时代，成为网络上的主宰者！</p>
        <div class="case-list">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" class="case-info" v-for="(item, index) in caseList" :key="index">
              <router-link :to="`/case/${item.id}`">
                <el-card shadow="hover">
                  <el-image fit="scale-down" :src="baseUrl + item.busCaseImage" :alt="item.busCaseDesc"/>
                </el-card>
              </router-link>
              <el-progress :percentage="50" :show-text="false"></el-progress>
              <p class="case-desc">{{ item.busCaseName }}</p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="margin-bottom: 20px; font-size: 18px;color: #5a5e66;text-align: center">
        <el-link :underline="false" @click="toProduct">查看更多</el-link>
      </div>
    </div>
  </div>
</template>
<script>
import {getServiceCaseList, getServiceCaseListIndex} from '@/api/work/case';

export default {
  name: "IndexService",
  data() {
    return {
      serviceIdeaInfo: {
        ideaTitle: '页面案例宗旨',
        ideaSubTitle: 'Studio Purpose',
        ideaTitleDesc: '专注品牌形象力事业十余年，是一个兼具国际视野、创新动力、全方位跨平台的软件定制化展示开发的个人独立设计。',
        ideaDesc: '专注品牌形象力事业十余年，是一个兼具创新动力、全方位跨平台的软件定制化展示开发的个人独立设计。业务案例涉及APP、WEB网站、企业门户、小程序、管理系统等。'
      },
      serviceIdeaImg: [
        {
          name: 'lefImg',
          path: require('../assets/img/index/service1.png')
        },
        {
          name: 'rightImg',
          path: require('../assets/img/index/kejikuan1.jpg')
        },
        {
          name: 'logoImg',
          path: require('../assets/img/index/kejichang.jpg')
        }
      ],
      caseList: [],
      bussList: [],
      percentage: 50,
      baseUrl: this.$baseUrl,
      isMobile: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 获取案例数据
      this.getServiceCase();
      // 获取业务数据
      this.getBussCaseList();
    },
    // 获取案例数据
    getServiceCase() {
      getServiceCaseListIndex().then(res => {
        this.caseList = res.data;
      })
    },
    getBussCaseList() {
      getServiceCaseList("buss").then(res => {
        this.bussList = res.data;
      })
    },
    // 跳转到案例页面
    toProduct() {
      this.$router.push('/product');
    }
  }
}
</script>
<style lang="less" scoped>
.wrap-block {
  position: relative;
  padding-top: 54px;

  .content-block {
    position: relative;
    overflow: hidden;
  }
}

.service-block {
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  overflow-x: hidden;

  .block-line {
    margin: 0 auto 12px;
    width: 24px;
    height: 4px;
    background-color: #3370ff;
    border-radius: 100px;
  }

  .service-info-title {
    font-weight: 700;
    font-size: 32px;
    position: relative;
    bottom: 2px;
    //padding-top: 60px;
  }

  .service-info-subtitle {
    font-size: 18px;
    line-height: 1.5;
    color: #646a73;
    margin: 10px 15px;
  }

  .service-list {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin: 30px auto 0 auto;
  }

  .is-hover-shadow {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service-title-shadow {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
  }

  /*.service-title-shadow:hover {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    transform: scale(1.05) translateY(-1px);
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .15);
  }*/

  .service-name {
    font-weight: 600;
    font-size: 18px;
  }
}

.service-idea {
  background-color: #F5F5F5;
  width: 100%;

  .service-idea {
    max-width: 1440px;
    overflow: hidden;
    display: flex;
  }

  .idea-left img {
    float: right;
    margin-right: 10px;
  }

  .idea-right {
    margin-left: 50px;
    padding-top: 50px;
    overflow: hidden;

    .block-line {
      margin: 0 0 12px 0;
      width: 24px;
      height: 4px;
      background-color: #3370ff;
      border-radius: 100px;
    }

    .idea-title {
      font-weight: 700;
      font-size: 32px;
      position: relative;
      bottom: 2px;
      //padding-top: 60px;
    }

    .idea-subtitle {
      font-size: 20px;
      line-height: 1.5;
      color: #646a73;
      margin-top: 10px;
    }

    img {
      width: 500px;
      height: 200px;
      object-fit: cover;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 10px;
      border: #d3dce6 2px dashed;
    }

    .idea-desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      max-height: 88px;
      max-width: 550px;
      font-size: 16px;
      line-height: 30px;
      color: #646a73;
    }

    .el-button {
      margin-top: 40px;
    }
  }
}

.service-case {
  background-color: #FFFFFF;
  width: 100%;

  .service-case {
    //height: 700px;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;

    .block-line {
      margin: 0 auto 12px;
      width: 24px;
      height: 4px;
      background-color: #3370ff;
      border-radius: 100px;
    }

    .case-title {
      font-weight: 700;
      font-size: 32px;
      position: relative;
      bottom: 2px;
      //padding-top: 60px;
    }

    .case-subtitle {
      font-size: 18px;
      line-height: 1.5;
      color: #646a73;
      margin: 10px 15px;
    }

    .case-list {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .el-card {
      margin-top: 40px;
    }

    .el-progress {
      margin-top: 10px;
    }

    /deep/ .el-progress-bar__outer {
      height: 2px !important;
    }

    .case-desc {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.studioBox {
  margin: 10px auto;
}

.studioLeftBox {
  margin: 10px 20px;
}

.studioLeftBoxImg {
  width: 100%;
  overflow: hidden;
}

.studioRightBox {
  margin: 10px 20px;
  overflow: hidden;
}

.studioRightBoxImg {
  width: 200px;
  overflow: hidden;
}


</style>
