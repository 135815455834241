import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b5c010cc&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=b5c010cc&prod&lang=less&scoped=true&"
import style1 from "./App.vue?vue&type=style&index=1&id=b5c010cc&prod&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5c010cc",
  null
  
)

export default component.exports