import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    BannerHeight: 0,
    headerShadowActive: true,
    headerShow: false,
    headerLogoShow: false,
    navDarkActive: true,
    articlePath: ''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
