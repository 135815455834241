<template>
  <div class="banner">
    <el-row>
      <el-col :span="24">
        <div class="bannerBox1">
          <el-carousel :height="imgHeight + 'px'" :interval="3000" arrow="hover">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <el-image class="bannerImg" 
                        :src="baseUrl + item.busBannerImage"
                        :alt="item.busBannerDesc"
                        fit="cover" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {getBannerList} from '@/api/work/banner';

export default {
  name: "Banner",
  props: ["imgItem", "bannerHeight"],
  data() {
    return {
      current: 0,
      imgList: [],
      baseUrl: this.$baseUrl,
      imgHeight: window.innerHeight / 2,
    }
  },
  created() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      getBannerList().then(res => {
        console.log(res);
        this.imgList = res.data;
      })
    }
  }
}
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 100%;
  margin-top: 63px;
}

.bannerImg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
}

.bannerBox {
  width: 100%;
  height: 100%;
}

.el-carousel {
  width: 100%
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.img_con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(51, 112, 255, .2);
    height: 100%;
    z-index: 666;
  }

  .element-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
}

:deep(.el-carousel__arrow) {
  display: block !important;
  width: 70px;
  height: 70px;
  font-size: 40px;
  background-color: rgba(90, 88, 88, 0.1);
}

:deep(.el-carousel__arrow:hover) {
  background-color: rgba(16, 15, 15, 0.3);
}
</style>
