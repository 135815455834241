<template>
    <div class="index">
        <Header></Header>
        <div class="banner-wrap">
            <Banner :banner-height="BannerHeight"></Banner>
            <over-lay></over-lay>
            <scroll-hint v-scroll-to="{ element: '.wrap-block', duration: 300, easing: 'ease', offset: 1 }"></scroll-hint>
        </div>
        <IndexService></IndexService>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Banner from '@/components/Banner';
import IndexService from '@/components/IndexService.vue';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Index",
    components: {
        Header,
        Footer,
        Banner,
        IndexService
    },
    data() {
        return {
            BannerHeight: window.innerHeight
        }
    }
}
</script>
<style lang="less" scoped>
.banner-wrap {
    overflow: hidden;
    position: relative;
}

.overlay {
    z-index: 666;
}

.wrap-block {
    position: relative;
    padding-top: 54px;
}
</style>
