import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/case/:id',
    name: 'CaseDetail',
    component: () => import('@/views/CaseDetail.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsDetail',
    component: () => import('@/views/NewsDetail.vue')
  },
  {
    path: '/product',
    name: 'Case',
    component: () => import('@/views/Case.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
