import request from '@/utils/request'

// 根据服务案例类型查询列表数据
export function getServiceCaseList(type) {
  return request({
    url: '/web/getServiceCaseListByType/' + type,
    method: 'get'
  })
}

// 获取首页服务案例列表数据
export function getServiceCaseListIndex() {
  return request({
    url: '/web/getServiceCaseListIndex',
    method: 'get'
  })
}

// 分页获取服务案例列表数据
export function getServiceCaseListPage(num) {
  return request({
    url: '/web/getServiceCaseListPage/' + num,
    method: 'get'
  })
}

// 根据id获取服务案例数据
export function getCaseById(id) {
  return request({
    url: '/web/getCaseById/' + id,
    method: 'get'
  })
}

